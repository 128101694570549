import { Hero } from 'components/markdown/hero';
import { MobileNavigation } from 'components/navigation/mobile-navigation';
import { navigationContentParse } from 'components/navigation/navigation-content-parse';
import { Button } from 'components/ui/button';
import { Link, LinkButton } from 'components/ui/link';
import { navigate } from 'gatsby';
import { useContent, useSiteMetadata } from 'hooks';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { SEO } from '../components/feature/seo';
import { DynamicImage } from '../components/images/dynamic-image';
import { NavBarWithLogo } from '../layouts/nav-bar';
import { RootContext } from '../providers/root-provider';

type NotFoundPageProps = {
  location: Location;
  title?: string;
  description?: string;
};

type HelpLinkType = {
  title:
    | 'pages.pageNotFound.helpAndSupport.title'
    | 'pages.pageNotFound.gettingStarted.title'
    | 'pages.pageNotFound.browseApi.title';
  href: string;
  description:
    | 'pages.pageNotFound.helpAndSupport.description'
    | 'pages.pageNotFound.gettingStarted.description'
    | 'pages.pageNotFound.browseApi.description';
  externalOnly?: boolean;
};

const helpLinkList: HelpLinkType[] = [
  {
    href: '/support',
    title: 'pages.pageNotFound.helpAndSupport.title',
    description: 'pages.pageNotFound.helpAndSupport.description',
  },
  {
    href: '/getting-started',
    title: 'pages.pageNotFound.gettingStarted.title',
    description: 'pages.pageNotFound.gettingStarted.description',
    externalOnly: true,
  },
  {
    href: '/catalogue',
    title: 'pages.pageNotFound.browseApi.title',
    description: 'pages.pageNotFound.browseApi.description',
  },
];

const HelpLinks = () => {
  const { formatMessage } = useIntl();
  const { externalSite } = useSiteMetadata();

  const links = externalSite ? helpLinkList : helpLinkList.filter((helpLink) => !helpLink.externalOnly);
  return (
    <div className="mt-10 flex flex-col gap-10">
      {links.map((helpLink) => (
        <div key={helpLink.title}>
          <Link href={helpLink.href} className="flex gap-1 text-sm font-medium leading-normal">
            {formatMessage({ id: helpLink.title })}
            <MdArrowForward />
          </Link>
          {formatMessage({ id: helpLink.description })}
        </div>
      ))}
    </div>
  );
};

const NotFound = ({ location }: { location: Location }) => {
  const { formatMessage } = useIntl();
  const nodes = useContent();
  const navItems = navigationContentParse(nodes);

  return (
    <>
      <NavBarWithLogo />
      <MobileNavigation location={location} items={navItems} />
      <main className="mx-4 flex justify-center lg:pt-8">
        <div className="flex items-center gap-8 md:w-3/4">
          <div className="flex w-fit flex-col justify-center lg:max-w-[50%]">
            <Hero
              subHeading={formatMessage({ id: 'pages.pageNotFound.subHeader' })}
              headline={formatMessage({ id: 'pages.pageNotFound.title' })}
              copy={formatMessage({ id: 'pages.pageNotFound.description' })}
            />
            <div className="flex flex-col gap-4">
              <LinkButton href="/">{formatMessage({ id: 'generic.controls.takeMeHomeButton' })}</LinkButton>
              <Button variant="outline" onClick={() => navigate(-1)} className="w-fit gap-2">
                <MdArrowBack />
                {formatMessage({
                  id: 'generic.controls.backToPreviousPageButton',
                })}
              </Button>
            </div>
            <HelpLinks />
          </div>
          <div className="mt-12 hidden bg-purple-300 lg:block">
            {DynamicImage({ name: '404-hero.svg', altText: '404 image' })}
          </div>
        </div>
      </main>
    </>
  );
};

export const NotFoundPage = ({ location, title, description }: NotFoundPageProps) => (
  <RootContext>
    <SEO title={title} description={description} pathname={location.pathname} lang="en" />
    <NotFound location={location} />
  </RootContext>
);

export default NotFoundPage;
